<template>
	<div class="layout-menu-container">
		<AppSubmenu :items="basico" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
		<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
	data() {
		return {
			basico: [
				{label: 'Inicio', icon: 'bi bi-house-fill', to: '/'},
				{label: 'Campus Virtual', icon: 'bi bi-vector-pen', url: 'https://iutirlavirtual.com'},
			]
		}
	},       
	props: {
		model: Array
	},
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        }
    },
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>

<style scoped>

</style>