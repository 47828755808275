<template>
	<div class="layout-footer">
		<!--<img src="logo.jpg" style="height: 40px;"/>-->
		<span class="footer-text" style="margin-right: 1px; margin-left: 1px; color: #d46900">
            <a href="#" target="_blank" class="p-mr-3">
				<Avatar icon="bi bi-instagram" style="background-color:#720000; color: #ffffff" shape="circle"/>
            </a>
            <a href="#" target="_blank" class="p-mr-3">
				<Avatar icon="pi pi-twitter" style="background-color:#720000; color: #ffffff" shape="circle"/>
            </a>
            <a href="#" target="_blank" class="p-mr-3">
				<Avatar icon="bi bi-facebook" style="background-color:#720000; color: #ffffff" shape="circle"/>
            </a>
		</span>
		<span class="footer-text" style="fontSize: 0.7rem; margin-right: 5px; margin-left: 5px; color: #575756">
			Copyright 2021. Instituto Universitario de Tecnología Industrial “Rodolfo Loero Arismendi” (IUTIRLA) RIF: J-00130751-6. Todos los derechos reservados.
		</span>
		<span class="footer-text" style="right: 1px">
			<a href="#" target="_blank" class="p-mr-3">
				<i class="pi pi-check-circle" style="fontSize: 1.5rem" v-if="state.estatus"></i>
				<i class="pi pi-exclamation-circle" style="fontSize: 1.5rem" v-else></i> 
			</a>
			<a href="#" target="_blank" class="p-mr-3">
				<i class="pi pi-mobile" style="fontSize: 1.5rem" v-if="state.mobile"></i>
				<i class="pi pi-desktop" style="fontSize: 1.5rem" v-else></i>
			</a>
			<span  class="footer-controls" style="fontSize: 1rem">
                V{{ version }}
            </span>
		</span>
		
	</div>
</template>

<script>
	import store from './store';
	import packageJson from '../package.json';

	export default {
		data() {
			return {
				state: null,
				version: packageJson.version
			}
		},
		created() {
			this.state = store.state;
		}
	}
</script>

<style scoped>

</style>