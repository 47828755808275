<template>
	<div>
        <Dialog header="Impresión" v-model:visible="$store.state.imp" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true" style="background: #727376;position: absolute;">
            <div class="card p-fluid p-formgrid p-grid">
				<br>
                <div class="p-field p-col-12 p-md-3">
                    <label>Margen Superior</label>
					<InputText v-model="$store.state.impresora.sup" type="number"></InputText>							
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <label>Margen Izquierdo</label>
					<InputText v-model="$store.state.impresora.izq" type="number"></InputText>							
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <label>Tipo de Letra</label>
                    <Dropdown v-model="$store.state.impresora.font" :options="letras"/>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <label>Tamaño de Letra</label>
					<InputText v-model="$store.state.impresora.size" type="number"></InputText>							
                </div>
			</div>	
            <template #footer>
                <Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Save"/>
            </template>
        </Dialog>
	</div>
   
</template>

<script>

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		}
	},
    data() {
        return {
			displayPerfil: false,
            perfil: null,
            size: '60vw',
            visibleFull: false,
            estados: null,
            ciudades: [],
            municipios: [],
            parroquias: [],
			url: null,
			token: null,
            letras: ['Times','Courier','Helvetica'],

		}
	},
    created() {      
        this.url = process.env.VUE_APP_API;
        if (this.$store.state.mobile){this.size = '80vw';}
    },
    methods: {
        Save() {
            localStorage.setItem('imp_sup', this.$store.state.impresora.sup);
            localStorage.setItem('imp_izq', this.$store.state.impresora.izq);
            localStorage.setItem('imp_font', this.$store.state.impresora.font);
            localStorage.setItem('imp_size', this.$store.state.impresora.size);
            this.$store.state.imp = false;
        },
	},
    watch: {
		display(newValue) {
			this.displayPerfil = newValue;
		}
	},


}
</script>