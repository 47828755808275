import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Inicio',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Home.vue')
    },
    {
        path: "/cronograma",
        name: 'Cronograma',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Cronograma.vue')
    },
    {
        path: "/aranceles",
        name: 'Aranceles',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Aranceles.vue')
    },
    {
        path: "/formapago",
        name: 'Forma de Pago',
        component: () => import(/* webpackChunkName: "Home" */ './pages/FormaPago.vue')
    },
    {
        path: "/soporte",
        name: 'Soporte',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Soporte.vue')
    },
    {
        path: "/preinscripcion",
        name: 'Preinscripción',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Preinscripcion.vue')
    },
    {
        path: "/pagospreinscripcion",
        name: 'PagosPreinscripcion',
        component: () => import(/* webpackChunkName: "Home" */ './views/PagosPreinscripcion.vue')
    },
    {
        path: "/preinscripcion_paso1",
        name: 'Preinscripcion',
        component: () => import(/* webpackChunkName: "Home" */ './components/Preinscripcion.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/Preinscripcion/Paso1.vue'),
            },
            {
                path: '/preinscripcion_paso2',
                component: () => import('./components/Preinscripcion/Paso2.vue'),
            },
            {
                path: '/preinscripcion_paso3',
                component: () => import('./components/Preinscripcion/Paso3.vue'),
            },
            {
                path: '/preinscripcion_paso4',
                component: () => import('./components/Preinscripcion/Paso4.vue'),
            },
            {
                path: '/preinscripcion_paso5',
                component: () => import('./components/Preinscripcion/Paso5.vue'),
            },
        ],
    },
    {
        path: "/carrera",
        name: 'Carrera',
        component: () => import(/* webpackChunkName: "Home" */ './views/Carrera.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/plandeestudio",
        name: 'Plan de Estudio',
        component: () => import(/* webpackChunkName: "Home" */ './views/PlandeEstudio.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/asignaturas",
        name: 'Asignaturas',
        component: () => import(/* webpackChunkName: "Home" */ './views/Asignaturas.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/estudiantes",
        name: 'Estudiantes',
        component: () => import(/* webpackChunkName: "Home" */ './views/Estudiantes.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/becas",
        name: 'Becas',
        component: () => import(/* webpackChunkName: "Home" */ './views/Becas.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/extensiones",
        name: 'Extensiones',
        component: () => import(/* webpackChunkName: "Home" */ './views/Extensiones.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/seccion",
        name: 'Seccion',
        component: () => import(/* webpackChunkName: "Home" */ './views/Seccion.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/oferta",
        name: 'Oferta',
        component: () => import(/* webpackChunkName: "Home" */ './views/Oferta.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/periodo",
        name: 'Periodo',
        component: () => import(/* webpackChunkName: "Home" */ './views/Periodo.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/estad_estudiantes",
        name: 'Estad_Estudiantes',
        component: () => import(/* webpackChunkName: "Home" */ './views/Estad_Estudiantes.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/estad_preinscripcion",
        name: 'Estad_Preinscripcion',
        component: () => import(/* webpackChunkName: "Home" */ './views/Estad_Preinscripcion.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/estad_inscripcion",
        name: 'Estad_Inscripcion',
        component: () => import(/* webpackChunkName: "Home" */ './views/Estad_Inscripcion.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/estad_administrativo",
        name: 'Estad_Administrativo',
        component: () => import(/* webpackChunkName: "Home" */ './views/Estad_Administrativo.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/estad_opsu",
        name: 'Estad_OPSU',
        component: () => import(/* webpackChunkName: "Home" */ './views/Estad_OPSU.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/estad_calificaciones",
        name: 'Estad_Calificaciones',
        component: () => import(/* webpackChunkName: "Home" */ './views/Estad_Calificaciones.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/doc_calif",
        name: 'Doc_Calif',
        component: () => import(/* webpackChunkName: "Home" */ './views/Doc_Calif.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/doc_hor",
        name: 'Doc_Hor',
        component: () => import(/* webpackChunkName: "Home" */ './views/Doc_Hor.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/doc_plan",
        name: 'Doc_Plan',
        component: () => import(/* webpackChunkName: "Home" */ './views/Doc_Plan.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/docentes",
        name: 'Docentes',
        component: () => import(/* webpackChunkName: "Home" */ './views/Docentes.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/aulas",
        name: 'Aulas',
        component: () => import(/* webpackChunkName: "Home" */ './views/Aulas.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/inscripcion",
        name: 'Inscripcion',
        component: () => import(/* webpackChunkName: "Home" */ './views/Inscripcion.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/inscripcionest",
        name: 'InscripcionEst',
        component: () => import(/* webpackChunkName: "Home" */ './views/InscripcionEst.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/histinscrip",
        name: 'HistInscrip',
        component: () => import(/* webpackChunkName: "Home" */ './views/HistInscrip.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/inscrip',
        name: 'Inscripción',
        component: () => import(/* webpackChunkName: "Home" */ './components/Inscripcion.vue'),
        meta: { authRequired: true },
        children: [
            {
                path: '',
                component: () => import('./components/Inscripcion/Paso1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/inscripcion_paso2',
                component: () => import('./components/Inscripcion/Paso2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/inscripcion_paso3',
                component: () => import('./components/Inscripcion/Paso3.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/inscripcion_paso4',
                component: () => import('./components/Inscripcion/Paso4.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },
    {
        path: '/inscripest',
        name: 'InscripciónEst',
        component: () => import(/* webpackChunkName: "Home" */ './components/InscripcionEst.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/InscripcionEst/Paso1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/inscripcionest_paso2',
                component: () => import('./components/InscripcionEst/Paso2.vue'),
                meta: { authRequired: true }
            },
            {
                path: '/inscripcionest_paso3',
                component: () => import('./components/InscripcionEst/Paso3.vue'),
                meta: { authRequired: true }
            },
            {
                path: '/inscripcionest_paso4',
                component: () => import('./components/InscripcionEst/Paso4.vue'),
                meta: { authRequired: true }
            },
            {
                path: '/inscripcionest_paso5',
                component: () => import('./components/InscripcionEst/Paso5.vue'),
                meta: { authRequired: true }
            },
        ],

    },
    {
        path: "/cons_estudiante",
        name: 'ConsEstudiante',
        component: () => import(/* webpackChunkName: "Home" */ './views/ConsEstudiante.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/cons_horario_aulas",
        name: 'ConsHorarioA',
        component: () => import(/* webpackChunkName: "Home" */ './views/ConsHorarioA.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/cons_horario_secciones",
        name: 'ConsHorarioS',
        component: () => import(/* webpackChunkName: "Home" */ './views/ConsHorarioS.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/cons_horario_docentes",
        name: 'ConsHorarioD',
        component: () => import(/* webpackChunkName: "Home" */ './views/ConsHorarioD.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/repacadestud",
        name: 'RepAcadEstud',
        component: () => import(/* webpackChunkName: "Home" */ './views/RepAcadEstud.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/pagos_estudiante",
        name: 'PagosEstudiante',
        component: () => import(/* webpackChunkName: "Home" */ './views/PagosEstudiante.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/pagoscuotas",
        name: 'PagosCuotas',
        component: () => import(/* webpackChunkName: "Home" */ './views/PagosCuotas.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/pagosinscrip",
        name: 'PagosInscrip',
        component: () => import(/* webpackChunkName: "Home" */ './views/PagosInscrip.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/pagosinscriptos",
        name: 'PagosInscriptos',
        component: () => import(/* webpackChunkName: "Home" */ './views/PagosInscriptos.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/pagosrecib",
        name: 'PagosRecib',
        component: () => import(/* webpackChunkName: "Home" */ './views/PagosRecib.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/ajustefactura",
        name: 'AjusteFactura',
        component: () => import(/* webpackChunkName: "Home" */ './views/AjusteFactura.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/preinscritos",
        name: 'Preinscritos',
        component: () => import(/* webpackChunkName: "Home" */ './views/Preinscritos.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/posigrad",
        name: 'PosiGrad',
        component: () => import(/* webpackChunkName: "Home" */ './views/PosiGrad.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/acta",
        name: 'Acta',
        component: () => import(/* webpackChunkName: "Home" */ './views/Acta.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/actaExt",
        name: 'ActaExt',
        component: () => import(/* webpackChunkName: "Home" */ './views/ActaExt.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/graduados",
        name: 'Graduados',
        component: () => import(/* webpackChunkName: "Home" */ './views/Graduados.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/mispagos",
        name: 'MisPagos',
        component: () => import(/* webpackChunkName: "Home" */ './views/MisPagos.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/miscalificaciones",
        name: 'MisCalificaciones',
        component: () => import(/* webpackChunkName: "Home" */ './views/MisCalificaciones.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/reportarpago",
        name: 'ReportarPago',
        component: () => import(/* webpackChunkName: "Home" */ './views/ReportarPago.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/reportarmipago",
        name: 'ReportarMiPago',
        component: () => import(/* webpackChunkName: "Home" */ './views/ReportarMiPago.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/validarpago",
        name: 'ValidarPago',
        component: () => import(/* webpackChunkName: "Home" */ './views/ValidarPago.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/seguimiento",
        name: 'Seguimiento',
        component: () => import(/* webpackChunkName: "Home" */ './views/Seguimiento.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/modificacion",
        name: 'Modificacion',
        component: () => import(/* webpackChunkName: "Home" */ './views/Modificacion.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/actascalif",
        name: 'ActasCalif',
        component: () => import(/* webpackChunkName: "Home" */ './views/ActasCalif.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/convalidacion",
        name: 'Convalidacion',
        component: () => import(/* webpackChunkName: "Home" */ './views/Convalidacion.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/auditar",
        name: 'Auditar',
        component: () => import(/* webpackChunkName: "Home" */ './views/Auditar.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/usuarios",
        name: 'Usuarios',
        component: () => import(/* webpackChunkName: "Home" */ './views/Usuarios.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/roles",
        name: 'Roles',
        component: () => import(/* webpackChunkName: "Home" */ './views/Roles.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/horas",
        name: 'Horas',
        component: () => import(/* webpackChunkName: "Home" */ './views/Horas.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/modalidad",
        name: 'Modalidad',
        component: () => import(/* webpackChunkName: "Home" */ './views/Modalidad.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/limpiar",
        name: 'Limpiar',
        component: () => import(/* webpackChunkName: "Home" */ './views/Limpiar.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/tasapetro",
        name: 'TasaPetro',
        component: () => import(/* webpackChunkName: "Home" */ './views/TasaPetro.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/tasadolar",
        name: 'TasaDolar',
        component: () => import(/* webpackChunkName: "Home" */ './views/TasaDolar.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/eliminar",
        name: 'Eliminar',
        component: () => import(/* webpackChunkName: "Home" */ './views/Eliminar.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/parametros",
        name: 'Parametros',
        component: () => import(/* webpackChunkName: "Home" */ './views/Parametros.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/formatos",
        name: 'Formatos',
        component: () => import(/* webpackChunkName: "Home" */ './views/Formatos.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/arancel",
        name: 'Arancel',
        component: () => import(/* webpackChunkName: "Home" */ './views/Arancel.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/facturas",
        name: 'Facturas',
        component: () => import(/* webpackChunkName: "Home" */ './views/Facturas.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/cedulas",
        name: 'Cedulas',
        component: () => import(/* webpackChunkName: "Home" */ './views/Cedulas.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/saldofavor",
        name: 'SaldoFavor',
        component: () => import(/* webpackChunkName: "Home" */ './views/SaldoFavor.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/crearsolicitud",
        name: 'CrearSolicitud',
        component: () => import(/* webpackChunkName: "Home" */ './views/CrearSolicitud.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/solicitudest",
        name: 'SolicitudEst',
        component: () => import(/* webpackChunkName: "Home" */ './views/SolicitudEst.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/solicitudes1",
        name: 'Solicitudes: Documentos',
        component: () => import(/* webpackChunkName: "Home" */ './views/Solicitudes1.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/solicitudes2",
        name: 'Solicitudes: Trámites',
        component: () => import(/* webpackChunkName: "Home" */ './views/Solicitudes2.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/impresion",
        name: 'Impresión',
        component: () => import(/* webpackChunkName: "Home" */ './views/Impresion.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/fact_report",
        name: 'ReporteFac',
        component: () => import(/* webpackChunkName: "Home" */ './views/ReporteFac.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/preforma",
        name: 'Preforma',
        component: () => import(/* webpackChunkName: "Home" */ './views/Preforma.vue'),
        meta: { authRequired: true }
    },

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
