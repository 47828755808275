<template>
	<div class="layout-topbar">
        <div class="layout-sup">
			<div class="layout-topbar-logo" @click="onMenuToggle">
				<img src="images/logo.png" v-if="this.$store.state.mobile" style="width: 70%;"/>
				<img src="images/logo.png" v-else/>
			</div>	
			<div class="layout-topbar-icons">
				<button class="p-link layout-menu-button" @click="onMenuToggle">
					<span class="pi pi-bars"></span>
				</button>
				<button class="p-link" style="text-align: center;" @click="openLogin()" v-if="!this.$store.state.autenticado">
					<span class="layout-topbar-item-text">Ingresar</span>
					<span class="layout-topbar-icon pi pi-sign-in"></span><br>
					<span class="ocultar">Ingresar</span>
				</button>
				<button class="p-link" style="text-align: center;" @click="Logout()" v-else>
					<span class="layout-topbar-item-text">Salir</span>
					<span class="layout-topbar-icon pi pi-fw pi-power-off"></span><br>
					<span class="ocultar">Salir</span>
				</button>
			</div>
		</div>
        <div class="layout-inf">

			<button class="p-link layout-topbar-logo">
				<strong style="margin-left: 20px;fontSize: 1.5rem;color: #575756;">Período: {{$store.state.periodo}} </strong>	
			</button>
		
			<div class="layout-topbar-icons">
				<button class="p-link layout-topbar-logo" v-if="$store.state.dolar">
					
					<span style="margin-left: 50px;">
						<i class="pi pi-dollar" style="font-size: 1.5rem"></i> Tasa BCV Bs.{{new Intl.NumberFormat("de-DE").format($store.state.dolar)}}
					</span>
					<!--
					<img src="images/petro.png"/>
					<span style="margin-left: 50px;">Bs.{{new Intl.NumberFormat("de-DE").format($store.state.petro)}}</span>
					-->
					<br>		
					<span style="margin-left: 50px;font-size: 1em;position: fixed;">Fecha Valor: {{$store.state.fecha}}</span>		
				</button>
			</div>
		</div>
		
	</div>
	<Login :display="displayLogin" @aprob-login="onMenuToggle" @close-login="close"/>
    <Register :display="displayRegister" @close-register="close"/>
    <Notificacion/>
	<Impresora/>



</template>

<script>
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import Notificacion from './components/Notificacion.vue';
import Impresora from './components/Impresora.vue';
import InactiveJS from "inactivejs";

export default {
	components: {
		Login,
		Register,
		Notificacion,
		Impresora
    },
    data() {
        return {
			displayLogin: false,
            displayRegister: false,
		}
	},
	created() {
		const onAway = () => {
			if (this.$store.state.autenticado){
				this.Tiempo();
			}		
		};
		const inactiveInstance = new InactiveJS({
			timeout: 30 * 60 * 1000, //30 min
			onAway: onAway
		});
		console.log(inactiveInstance);
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
        openLogin() {
            this.$store.commit('Mobile');
            this.displayRegister = false;
            this.displayLogin = true;
        },
        openRegister() {
            this.$store.commit('Mobile');
            this.displayLogin = false;
            this.displayRegister = true;
        },
        close() {
            this.displayLogin = false;
            this.displayRegister = false;
        },
        Logout() {
            this.$store.commit('Logout');
            //this.onMenuToggle();
            this.$router.push({ path: '/' });
        },
        Tiempo() {
			this.$toast.add({severity:'error', summary: 'Mensaje', detail:'El tiempo de inactividad fue alzanzado.', life: 3000});
            this.$store.commit('Logout');
            this.$router.push({ path: '/' });
        },
    }
}
</script>
