import { createStore } from 'vuex';
import API from "../service/API";
import router from '../router.js';

export default createStore({
  state: {
    mantenimiento: false,
    loading: false,
    conexion: false,
    autenticado: false,
    tipo: null,
    //authenticated: false,
    valido: false,
    fechaini: null,
    fecha: null,
    fechaletras: null,
    tope: null,
    year: null,
    mes: null,
    id: null,
    foto: 'foto',
    url: null,
    correo: null,
    usuario: null,
    nombre: null,
    apellido: null,
    nucleo: null,
    rol: 0,
    periodo: null,
    verificacion: false,
    estatus: false,
    mobile: false,
    error: null,
    admin: false,
    paginas: [],
    menu : [
      //{label: 'Cronograma', icon: 'bi bi-globe', to: '/cronograma'},
      //{label: 'Aranceles', icon: 'bi bi-shield-check', to: '/aranceles'},
      //{label: 'Forma de Pago', icon: 'bi bi-credit-card', to: '/formapago'},
      //{label: 'Soporte al Usuario', icon: 'bi bi-check2-square', to: '/soporte'},
    ],
    productos: [],
    menuser: [],
    menuadmin: [],
    menu_ini: [],
    seleccion: {
      caso: null,
      id: 0
    },
    resumen: {},
    matricula: {},
    petro: 0,
    dolar: 0,
    preinscripcion: null,
    conectados: 0,
    notificacion: false,
    notificaciones: [],
    deuda: false,
    imp: false,
    impresora: {
      sup: 0,
      izq: 0,
      font: 'Helvetia',
      size: 10,
    }
  },
  mutations: {
    Loading(){
      this.state.loading = false;	
    },
    Estatus(){
      //this.state.loading = true;
      this.state.url = process.env.VUE_APP_API+'doc/';	
      const Consulta = new API();
      Consulta.Estatus().then(response => {
        //this.state.loading = false;
        //this.state.error = response
        this.state.mantenimiento = response.mantenimiento;
        this.state.estatus = response.estatus;
        this.state.periodo = response.periodo;
        this.state.preinscripcion = response.preinscripcion;
        if (response.preinscripcion){
          this.state.menu.push({label: 'Preinscripción Alumnos Nuevos', icon: 'bi bi-journal-bookmark', to: '/preinscripcion'});
        }
        if (response.tasa.length){
          this.state.dolar = response.tasa[0].monto;
        }
        this.state.year = response.year;
        this.state.mes = response.mes;
        this.state.tope = response.topebs.toFixed(2); 
        this.state.fechaini = response.fecha;
        this.state.fechaletras = response.fechaletras;
        this.state.fecha = response.fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
        localStorage.setItem('Fecha', this.state.fecha);
        this.state.conectados = response.conectados;
        if(localStorage.getItem('imp_sup')){this.state.impresora.sup = localStorage.getItem('imp_sup'); }
        if(localStorage.getItem('imp_izq')){this.state.impresora.izq = localStorage.getItem('imp_izq'); }
        if(localStorage.getItem('imp_font')){this.state.impresora.font = localStorage.getItem('imp_font'); }
        if(localStorage.getItem('imp_size')){this.state.impresora.size = localStorage.getItem('imp_size'); }
        this.state.conectados = response.conectados+1;
      });
    },
    Mobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        && screen.width<1024) {
        this.state.mobile = true;
      } else {
        this.state.mobile = false;
      }    
    },
    Login(state,valores){
      //this.state.error = valores;
      this.state.autenticado = true;	
      this.state.tipo = valores.tipo;
      this.state.id = valores.id;
      localStorage.setItem('Token', valores.token);
      this.state.usuario = valores.nombre+' '+valores.apellido;   
      this.state.nombre = valores.nombre;        
      if(valores.foto){this.state.foto = valores.foto;}
      this.state.apellido = valores.apellido;   
      this.state.nucleo = valores.nucleo;
      this.state.correo = valores.correo;
      this.state.valido = valores.valido;
      this.state.menu_ini = this.state.menu;
      this.state.admin = valores.admin;
      this.state.deuda = valores.deuda;
      if(valores.validando){
        this.state.menu = valores.menu.filter(val => val.modulo == 21);
        this.state.menu[0].items = this.state.menu[0].items.filter(val => val.label=="Mis Pagos");
      } else if(valores.deuda){
        this.state.menu = valores.menu.filter(val => val.modulo == 21);
      } else {
        this.state.menu = valores.menu;
      }
      if(valores.notificaciones.length>0){
        this.state.notificaciones = valores.notificaciones;
        this.state.notificacion = true;
      }
      if (valores.admin) {
        this.state.menuadmin = valores.menuadmin;
        this.state.menuadmin.forEach(element => {
            this.state.paginas.push(element.to);
        });  
      }
      this.state.menu.forEach(element => {
        element.items.forEach(element => {
          this.state.paginas.push(element.to);
        }); 
      });  
      if(valores.admin){
        this.state.preinscripcion = true;
        this.state.menu.push({label: 'Preinscripción Alumnos Nuevos', icon: 'bi bi-journal-bookmark', to: '/preinscripcion'});
      }
    },
    Validar(state,pagina){
      this.state.insert = false;
      this.state.edit = false;
      this.state.delete = false;
      if(!this.state.paginas.includes(pagina.path)){
        router.push({ path: '/' });
      } 
    },
    Seleccion(state,valores){
      this.state.seleccion.caso = valores.caso;
      this.state.seleccion.id = valores.id;
    },
    Matricula(state,valores){
      this.state.matricula = valores.matricula;
    },
    ValorPetro(){
      const url = 'https://petroapp-price.petro.gob.ve/price/';
			const data = {
				"coins": ["PTR"],
				"fiats" : ["BS","USD"]
			};
			fetch(url, {
				method: 'POST',
				body: JSON.stringify(data), 
				headers:{
				'Content-Type': 'application/json'
				}
			})
			.then(res => res.json())		
			.then(response => {
        if(response){
          
          this.state.petro = response.data.PTR.BS;
        }
			});
    },
    Logout(){
      const Consult = new API('Seguridad','Exit');
      Consult.Ini();
      localStorage.removeItem('Token');
      localStorage.removeItem('Fecha');
      this.state.autenticado = false;
      this.state.usuario = null;   
      this.state.tipo = null;   
      this.state.correo = null;
      this.state.valido = false;
      this.state.admin = false;
      this.state.paginas = [];
      this.state.menu = this.state.menu_ini;
      this.state.menuadmin = [];
      router.push({ path: '/' });
    }
  },
  actions: {
  },
  modules: {
  }
})
