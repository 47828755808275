import axios from "axios";
import store from "../store";
import router from '../router.js';

const URL = process.env.VUE_APP_API;

const API = function (parameters){
  const http = axios.post(URL, parameters, { timeout: 600000 }); //60 segundos
  return http; 
}

class APIs {
    constructor(modulo=null,funcion=null) {
        this.modulo =   modulo;
        this.funcion =  funcion;
        this.tipo =     store.state.tipo;
        this.token =    localStorage.getItem('Token');
        store.state.conexion = false; 
        store.state.loading = false;
    }
    destructor() { 
        store.state.loading = false;
    }
    Estatus() {
        store.state.loading = true;
        return API({
            modulo:  this.modulo
        }).then(res => {
            store.state.loading = false;
            return res.data;
        }).catch(error => { 
            this.ErrorConexion(error);
        });
    }

    Ini() {
        store.state.loading = true;
        return API({
            modulo:     this.modulo,
            funcion:    this.funcion,
            token:      this.token,
            tipo:       this.tipo,
        }).then(res => {
            store.state.loading = false;
            return res.data;
        }).catch(error => { 
            store.state.loading = false;
            this.ErrorConexion(error);
        });
      }




    Ini2() {
        //store.state.loading = true;
        return API({
            modulo:     this.modulo,
            funcion:    this.funcion,
            token:      this.token,
            tipo:       this.tipo,
        }).then(res => res.data)
        .catch(error => { 
            this.ErrorConexion(error);
        });
    }
    Procesar(cas,array) {
        store.state.loading = true;
        return API({
            modulo:     this.modulo,
            funcion:    this.funcion,
            token:      this.token,
            tipo:       this.tipo,
            case:       cas,
            array:      array
        }).then(res => res.data).catch(error => { 
            this.ErrorConexion(error);
        });
    }

    ErrorConexion(error) {
        console.log(error);
        store.state.loading =       false;
        store.state.conexion =      true;
        store.state.autenticado =   false;
        store.state.usuario =       null;
        store.state.correo =        null;
        store.state.valido =        false;
        store.state.admin =         false;
        store.state.paginas =       [];
        store.state.menu =          store.state.menu_ini;
        store.state.menuadmin =     [];
        router.push({ path: '/' });  
    }
}

export default APIs;